import React from 'react';
import GPSTracker_GG from '../components/GPSTracker_GG';

function TestGPS_GG() {
  return (
    <div className="App">
      <h1>ติดตาม GPS Google และระยะทางที่วิ่ง </h1>
      <GPSTracker_GG />
    </div>
  );
}

export default TestGPS_GG;
